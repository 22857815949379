<template>
    <div class="preset-selector">
      <h1>Выберите пресет сцен</h1>
      <ul>
        <li v-for="(preset, index) in presets" :key="index">
          <router-link :to="{ name: 'game', params: { presetId: preset.id } }">
            {{ preset.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        presets: [
          { id: 1, name: 'Пресет 1' },
          { id: 2, name: 'Пресет 2' },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .preset-selector {
    background-color: #f0f4f8; /* Светлый фон */
    border-radius: 8px; /* Закругленные углы */
    padding: 20px; /* Отступы внутри контейнера */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Тень для глубины */
    max-width: 400px; /* Максимальная ширина */
    margin: auto; /* Центрирование */
  }
  
  .preset-selector h1 {
    font-family: 'Arial', sans-serif; /* Шрифт заголовка */
    color: #333; /* Цвет текста заголовка */
    text-align: center; /* Выравнивание по центру */
  }
  
  .preset-selector ul {
    list-style-type: none; /* Убираем маркеры списка */
    padding: 0; /* Убираем отступы списка */
  }
  
  .preset-selector li {
    margin: 10px 0; /* Отступы между элементами списка */
  }
  
  .preset-selector a {
    display: block; /* Делаем ссылку блочным элементом */
    padding: 15px; /* Отступы внутри ссылки */
    text-decoration: none; /* Убираем подчеркивание */
    background-color: #007bff; /* Цвет фона ссылки */
    color: white; /* Цвет текста ссылки */
    border-radius: 5px; /* Закругленные углы для ссылок */
    transition: background-color 0.3s ease; /* Плавный переход цвета фона */
  }
  
  .preset-selector a:hover {
    background-color: #0056b3; /* Цвет фона при наведении */
  }
  </style>