  <template>
    <div class="game-container" @click="handleContainerClick">
      <div class="background" :style="{ backgroundImage: `url(${currentScene.background})` }"></div>
      <div class="character" :style="{ backgroundImage: `url(${currentScene.character})` }"></div>
      <div class="text-window">
        <p class="game_message">{{ currentScene.text }}</p>
        <div v-if="currentScene.choices.length" class="choices-container">
          <button v-for="(choice, index) in currentScene.choices" :key="index" @click.stop="selectChoice(choice)">
            {{ choice.text }}
          </button>
        </div>
      </div>

      <!-- Окно окончания игры -->
      <div v-if="isGameOver" class="game-over">
        <p>{{ currentScene.isFinalScene ? 'Вы достигли конца игры!' : 'Игра закончилась' }}</p>
        <button @click="exitGame" class="button_over">Назад</button>
      </div>

      <!-- Сообщение для мобильных пользователей -->
      <div v-if="isMobileDevice && !isLandscape" class="mobile-message">
        <p>Мы заметили что вы используете телефон. Для более удобного прохождения истории переверните ваше устройство.</p>
      </div>
    </div>
  </template>
    
    <script>
    // Импорт изображений
    import character1 from '@/assets/character1.png';
    import character2 from '@/assets/character2.png';
    import character3 from '@/assets/character3.png';
    import background1 from '@/assets/background1.png';
    import background2 from '@/assets/background2.png';
    import background3 from '@/assets/background3.png';
    
    export default {
      props: ['presetId'], // Получаем presetId как пропс
      data() {
        return {
          scenes: this.loadScenes(this.presetId), // Загружаем сцены на основе presetId
          currentSceneIndex: 0,
          isGameOver: false,
          isMobileDevice: false,
          isLandscape: false,
        };
      },
      computed: {
        currentScene() {
          return this.scenes[this.currentSceneIndex];
        },
      },
      methods: {
        loadScenes(presetId) {
          // Загружаем сцены на основе выбранного пресета
          if (presetId === '1') {
            return [
              {
                text: "00 Вы просыпаетесь в странном месте.",
                character: null,
                background: background1,
                choices: [
                  { text: "Налево", nextScene: 2 },
                  { text: "Направо", nextScene: 3 },
                ],
              },
              {
                text: "01 Вы видите перед собой два пути. Куда пойдете?",
                character: character2,
                background: background2,
                choices: [
                  { text: "Налево", nextScene: 2 },
                  { text: "Направо", nextScene: 3 },
                ],
              },
              {
                text: "02 Вы выбрали налево и встретили друга.",
                character: character3,
                background: background3,
                choices: [{ text: "Далее", nextScene: 4 }],
              },
              {
                text: "03 Вы выбрали направо и встретили врага.",
                character: character3,
                background: background3,
                choices: [],
              },
              {
                text: "04 Игра закончилась.",
                character: character3,
                background: background3,
                choices: [],
              },
            ];
          } else if (presetId === '2') {
            return [
              // Татьяна перс 2
              {
                text: "Татьяна сидела за работой в офисе как вдруг раздался звонок.",
                character: null,
                background: background1,
                choices: [],
              },
              {
                text: "Привет мам!",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Привет",
                character: character2,
                background: background1,
                choices: [],
              },
              {
                text: "У меня для тебя отличные новости! Я выиграла две визы в Турцию на работе!",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Это замечательно! От меня что-нибудь требуется?",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Да, мам. Нужен твой паспорт. Скинь его пожалуйста на этот номер",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "А почему именно на этот? Кстати, почему ты с незнакомого какого-то звонишь?",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Мой телефон разрядился, взяла у коллеги. Ну же мам, это срочно! Иначе визу отдадут кому-то другому!",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Коллега спрашивает кто звонит",
                character: null,
                background: background1,
                choices: [],
              },
              {
                text: "Да дочка моя Ленка, говорит визы выйграла сейчас паспорт ей скину.",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "А ты точно уверена что это она?",
                character: character1,
                background: background1,
                choices: [
                  { text: "Конечно, какие глупости!", nextScene: 11 },
                  { text: "А ведь и правда...", nextScene: 16 },
                ],
              },
              // ТУТ НАЧИНАЕТЬСЯ РАЗВИЛКА!
              {
                text: "Татьяна посылает фотографию паспорта на номер.",
                character: null,
                background: background1,
                choices: [],
              },
              {
                text: "Спасибо мам! Ура скоро в турцию полетим! ",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Да, я так рада.",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Через пару часов Татьяна обнаруживает что на неё взято 5 кредитов",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "*Вы были обмануты мошенником, который имитировал голос ваших близких при помощи нейросети.",
                character: character1,
                background: background1,
                choices: [],
                isFinalScene: true,

              },
              // Второй варик
              {
                text: "Ленусь, а как там Вадик?",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "А да всё в порядке",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "От наркоза уже отошел?",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Да, уже во всю борщ кушает!",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Борщ?! Лена! Вадик это твой пёс!",
                character: character1,
                background: background1,
                choices: [],
              },
              { // 22
                text: "*В растерянности* Да я же просто пошутила! Так что там с фото? Нас долго ждать не будут!",
                character: character1,
                background: background1,
                choices: [
                  { text: "Отправить фото", nextScene: 11 },
                  { text: "Продолжить разговор", nextScene: 23 },
                ],
              },
              // отправка фото в первую концовку.
              {
                text: "Доча, напомни как называется твоя компания, тётя Ира спрашивает.",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Ну ты ведь и сама знаешь мам!",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Запамятовала доча, а что же ты не помнишь как называется собственная компания?",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Эм... это...",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "Ага, попалась! Никакая ты не Лена! Я сейчас же позвоню дочери!",
                character: character1,
                background: background1,
                choices: [],
              },
              {
                text: "*Вы сохранили свои данные. Мошенник не смог обмануть вас, имитируя голос вашей дочери при помощи нейросети.",
                character: character1,
                background: background1,
                choices: [],
                isFinalScene: true,
              }
              
              // Другие сцены для второго пресета...
            ];
          }
          // Возвращаем пустой массив, если не найдено подходящих сцен
          return [];
        },
        checkDevice() {
          const ua = navigator.userAgent;
          this.isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone/i.test(ua);
          
          this.isLandscape = window.innerWidth > window.innerHeight;
        },
        handleResize() {
          this.isLandscape = window.innerWidth > window.innerHeight;
        },
        handleContainerClick() {
          if (!this.currentScene.choices.length) {
            this.nextScene(); // Proceed to the next scene if there are no choices
          }
        },

        nextScene() {
          if (this.currentScene.isFinalScene) {
            this.isGameOver = true; // Set game over flag
          } else if (this.currentSceneIndex < this.scenes.length - 1) {
            this.currentSceneIndex++;
          } else {
            this.isGameOver = true; // Set game over flag
          }
        },
      
        selectChoice(choice) {
          this.currentSceneIndex = choice.nextScene; // Move to the selected scene
        },
        exitGame() {
          window.location.href = '/'; // Замените на нужный URL
        },
      },
      mounted() {
        this.checkDevice(); // Проверяем устройство при монтировании компонента
        window.addEventListener('resize', this.handleResize); // Добавляем обработчик события resize
      },
      beforeUnmount() {
        window.removeEventListener('resize', this.handleResize); // Удаляем обработчик при уничтожении компонента
      }
    };
    </script>
    
  <style scoped>
  .game-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover; /* Масштабировать изображение для заполнения контейнера */
    background-position: center; /* Центрирует изображение по центру */
    background-repeat: no-repeat; /* Не повторять изображение */
  }

  .character {
    position: absolute;
    left: 5%; /* Положение персонажа слева */
    bottom: 10%; /* Положение персонажа */
    width: 50%; /* Задайте фиксированную ширину */
    height: 50%; /* Задайте фиксированную высоту */
    background-size: contain; /* Масштабирование без обрезки */
    background-repeat: no-repeat; /* Не повторять изображение */
  }

  .text-window {
    position: absolute;
    bottom: 10%;
    left: 20%;
    right: 20%;
    background-color: rgba(255,255,255,0.9); /* Slightly more opaque */
    padding: 15px;
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0px 4px 20px rgba(0,0,0,0.2); /* Add shadow for depth */
  }
  .choices-container {
    display: flex; /* Используем Flexbox для расположения кнопок */
    flex-wrap: wrap; /* Позволяем кнопкам оборачиваться на новую строку */
    justify-content: center; /* Центрируем кнопки по горизонтали */
  }

  button {
    background-color: #007BFF; /* Цвет кнопки */
    color: white; /* Цвет текста */
    border: none; /* Убираем рамку */
    border-radius: 12px; /* Скругленные углы */
    padding: 10px; /* Отступы для кнопки */
    margin: 5px; /* Отступ между кнопками */
    cursor: pointer; /* Курсор при наведении */
    width: calc(50% - 10px); /* Ширина кнопки, чтобы две кнопки помещались на строку с учетом отступов */
  }

  button:hover {
    background-color: #0056b3; /* Темнее цвет при наведении */
  }

  .button_over {
    background-color: #007BFF; /* Цвет кнопки */
    color: white; /* Цвет текста */
    border: none; /* Убираем рамку */
    border-radius: 12px; /* Скругленные углы */
    padding: 10px; /* Отступы для кнопки */
    margin: 5px; /* Отступ между кнопками */
    cursor: pointer; /* Курсор при наведении */
    width: 50%; /* Ширина кнопки, чтобы две кнопки помещались на строку с учетом отступов */
  }

  .button_over:hover {
    background-color: #0056b3; /* Темнее цвет при наведении */
  }

  .game-over {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.9);
    padding: 20px;
    border-radius: 10px;
  }

  .game_message {
    text-align: center; /* Center the text */
    margin: 0; /* Remove any default margins */
  }

  /* Стиль для сообщения о мобильном устройстве */
  .mobile-message {
    position:absolute;
    top : 30%;
    left : 50%;
    transform : translate(-50%, -50%);
    background-color : rgba(255,255,255,0.9);
    padding :20px;
    border-radius :10px;
    text-align:center;
  }
  </style>